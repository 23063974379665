<template>
  <v-card :height="pageHeight(165)" class="card-border">
    <v-dialog
        v-model="dialog"
        width="600"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
    >
        <v-card class="overflow-hidden" style="position:relative; max-height:780px">
          <v-icon size="30" color="black" style="position:absolute; top:10px;right:10px;"  @click="closeDialog">mdi-close</v-icon>
          <img :src="currentPhoto" width="600" height="800">
        </v-card>
        </v-dialog>
    <v-card-title class="border-bottom-notifications">
      <v-row dense class="d-flex mt-n3">
        <v-col cols="1" class="text-left">
          <v-icon color="black">mdi-bell</v-icon>
        </v-col>
        <v-col cols="5">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :label="$t('type')"
                  outlined
                  hide-details
                  readonly
                  dense
                  v-model="items[tab].text"
              >
              </v-text-field>
            </template>
            <v-tabs v-model="tab" vertical class="text-left">
              <v-tab v-for="(item, i) in items" :key="i" class="text-left">
                <span class="text-left font-size12">{{ item.text }}</span>
              </v-tab>
            </v-tabs>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <Search v-model="search"></Search>
        </v-col>

      </v-row>
    </v-card-title>
    <v-data-table :items="filteredNotifications"
                  fixed-header
                  :loading="loading"
                  :height="pageHeight(46)"
                  :headers="headers"
                  :footer-props="footerProps"
                  class="mt-n2 pointer"
                  :no-data-text="$t('no-data-available')"
                  :loading-text="$t('loading')">
      <template v-slot:item="{ item }">
        <tr @click="rowClick(item)"
          v-if="matchesSearch(item)">
          <td class="text-left">
            <div class="d-flex justify-space-between align-center">
              <div>
                <v-icon
                    :color="getIconColor(item.NotificationTypeId)"
                    :class="getIconClass(item.NotificationTypeId)"
                    size="13"
                />
                <span
                    :class="getSpanStyle(item.NotificationTypeId)"
                    v-text="formatDate(item.NotificationTimestamp)"
                    class="font-size12 font-weight-bold mt-n3"/>
              </div>
              <span v-if="item.NotificationTypeId === 1" class="font-weight-bold font-size12-5">By : {{item.fromUserFullname}}</span>
            </div>
            <div class="font-weight-bold">{{$i18n.locale === 'en' ? item.MessageToSend : item.MessageToSendEsp}}</div>
            <div>
              <span v-if="item.AttachedFile !== null">
                <div class="d-flex align-center">
                  <v-icon v-if="openedImage !== item.NotificationId"  @click.stop="checkPhoto(item)" color="primary">mdi-image</v-icon>
                  <img @click.stop="enlargenImage(item)" v-if="openedImage === item.NotificationId" width="200" :src="currentPhoto"/>
                </div>
              </span>
            </div>
          </td>
       </tr>
    </template>
                  </v-data-table>
                </v-card>
              </template>
              <script>
              import { mapGetters, mapState } from 'vuex';
              import Search from '@/views/Client/vuetify-components/Search.vue';

              export default {
                components: {
                  Search,
                },
                data () {
                  return {
                    dialog: false,
                    currentPhoto: null,
                    tab: 0,
                    loading: true,
                    search: '',
                    headerClass: 'color-header',
                    openedImage: false,
                    footerProps: { 'items-per-page-options': [-1, 50, 100] },
                    items: [
                      { text: this.$t('all-notifications') },
                      { text: this.$t('Chat-Notifications') },
                      { text: this.$t('abnormal-notifications') },
                      { text: this.$t('missed-notifications') },
                      { text: this.$t('marked-notifications') },
                    ],
                  };
                },
                computed: {
                  ...mapState({
                    filterCase: (state) => state.authentication.filterCase,
                    notifications: (state) => state.notifications.allNotifications,
                    abnormalNotifications: (state) => state.notifications.abnormalMeasurements,
                    missed: (state) => state.notifications.missMeasurements,
                    chatNotifications: (state) => state.notifications.chatNotifications,
                  }),
                  ...mapGetters({
                    pageHeight: 'pageHeight',
                    roleName: 'authentication/getRole',
                  }),
                  headers () {
                    var headers = [];
                    headers = [
                      {
                        text: this.$t('notifications'),
                        class: this.headerClass,
                      },
                    ];
                    return headers;
                  },
                  filteredNotifications () {
                    const notReadFilter = (notifications) => notifications.filter((notification) => !notification.IsRead);
                    const readFilter = (notifications) => notifications.filter((notification) => notification.IsRead);
                    switch (this.tab) {
                      case 0:
                        return notReadFilter(this.notifications);
                      case 1:
                        return notReadFilter(this.chatNotifications);
                      case 2:
                        return notReadFilter(this.abnormalNotifications);
                      case 3:
                        return notReadFilter(this.missed);
                      case 4:
                        return readFilter(this.notifications);
                      default:
                        return notReadFilter(this.notifications);
                    }
                  },
                  getIconColor () {
                    return (notificationTypeId) => {
                      switch (notificationTypeId) {
                        case 5:
                          return 'red';
                        case 3:
                          return 'orange';
                        case 12:
                          return 'primary';
                        case 1:
                          return 'primary';
                        case 4:
                          return 'alert_missed';
                        default:
                          return 'primary';
                      }
                    };
                  },
                  getIconClass () {
                    return (notificationTypeId) => {
                      switch (notificationTypeId) {
                        case 5:
                          return 'mdi mdi-alert';
                        case 3:
                          return 'mdi mdi-call-missed';
                        case 12:
                          return 'mdi mdi-message-alert';
                        case 1:
                          return 'mdi mdi-message-bulleted';
                        case 4:
                          return 'mdi mdi-bell-ring';
                        case 7:
                          return 'mdi mdi-message-alert';
                        default:
                          return 'mdi mdi-message-alert';
                      }
                    };
                  },
                  getSpanStyle () {
                    return (notificationTypeId) => {
                      const colorMap = {
                        5: 'red-icon',
                        3: 'orange-text',
                        12: 'color-primary',
                        13: 'color-primary',
                        1: 'darkpurple-text',
                        4: 'darkorange-text',
                      };
                      return colorMap[notificationTypeId] || '';
                    };
                  },
                  formatDate () {
                    return (timestamp) => {
                      return new Date(timestamp).toLocaleString('en-US', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                      });
                    };
                  },
                  language () {
                    return this.$i18n.locale === 'en' ? 'MessageToSend' : 'MessageToSendEsp';
                  },
                },
                created () {
                  this.loading = true;
                  this.getMyNotifications().then(() => {
                    this.loading = false;
                  });
                },
                // mounted () {
                //   this.loading = true;
                //   this.getMyNotifications().then(() => {
                //     this.loading = false;
                //   });
                // },
                methods: {
                  sinhronizeDrawers () {
                    const parameters = {
                      detailsDrawer: true,
                      page: this.$route.name.toLowerCase(),
                    };
                    this.$store.commit('SET_DETAILS_DRAWER', parameters);
                  },
                  closeDialog () {
                    this.dialog = false;
                  },
                  enlargenImage () {
                  this.dialog = true;
                  },
                  checkPhoto (item) {
                    const mimeType = 'image/png';
                    this.currentPhoto = `data:${mimeType};base64,${item.AttachedFile}`;
                    this.openedImage = item.NotificationId;
                  },
                  async getMyNotifications () {
                    await this.$store.dispatch(
                        'notifications/ALL_NOTIFICATIONS',
                        this.$cookies.get('datafilter'),
                    );
                  },
                  matchesSearch (item) {
                    const query = this.search.toLowerCase().trim();
                    const formattedDate = new Date(item.NotificationTimestamp).toLocaleString('en-US', {
                      month: 'short',
                      day: '2-digit',
                      year: 'numeric',
                    }).toLowerCase();
                    return (
                        item.fromUserFullname.toLowerCase().includes(query) ||
                        (this.language === 'MessageToSend' ? item.MessageToSend : item.MessageToSendEsp).toLowerCase().includes(query) ||
                        formattedDate.includes(query)
                    );
                  },
                  async rowClick (item) {
                    if (item.IsRead) {
                      return;
                    }
                    if (item.NotificationTypeId === 1) {
                      await this.$store.dispatch('users/getUserById', item.FromUserId);
                      await this.$router.push({ path: `/patientdetails/${item.FromUserId}` });
                      const body = {
                        notificationId: item.NId,
                        resolutionComments: item.ResolutionComments,
                      };
                      this.loadingRow = true;
                      await this.$store.dispatch('notifications/markNotificationsAsProcessed', body);
                    }
                    if (item.NotificationTypeId === 4) {
                      const body = {
                        notificationId: item.NId,
                        resolutionComments: item.ResolutionComments,
                      };
                      this.loadingRow = true;
                      await this.$store.dispatch('notifications/markNotificationsAsProcessed', body);
                    }
                    if (item.NotificationTypeId === 3) {
                      const body = {
                        notificationId: item.NId,
                        resolutionComments: item.ResolutionComments,
                      };
                      this.loadingRow = true;
                      await this.$store.dispatch('notifications/markNotificationsAsProcessed', body).then(res => {
                        this.getMyNotifications();
                      });
                    }
                    if (item.NotificationTypeId === 5) {
                      const body = {
                        notificationId: item.NId,
                        resolutionComments: item.ResolutionComments,
                      };
                      this.loadingRow = true;
                      await this.$store.dispatch('notifications/markNotificationsAsProcessed', body);
                    }
                    if (item.NotificationTypeId === 7) {
                      item.IsRead = false;
                      this.sinhronizeDrawers();
                      await this.$store.dispatch('notifications/propsNotification', item);
                    }
                    if (item.NotificationTypeId === 12) {
                      await this.$router.push({ path: `/patientdetails/${item.FromUserId}` });
                      const body = {
                        notificationId: item.NId,
                        resolutionComments: item.ResolutionComments,
                      };
                      this.loadingRow = true;
                      await this.$store.dispatch('notifications/markNotificationsAsProcessed', body);
                    }
                    if (item.NotificationTypeId === 13) {
                      await this.$router.push({ path: `/patientdetails/${item.FromUserId}` });
                      const body = {
                        notificationId: item.NId,
                        resolutionComments: item.ResolutionComments,
                      };
                      this.loadingRow = true;
                      await this.$store.dispatch('notifications/markNotificationsAsProcessed', body);
                    }
                    this.loadingRow = false;
                  },
                },
              };
              </script>
